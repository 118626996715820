import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/login',
      component: () => import('@/views/pages/Index'),
      children: [
        {
        name: 'Login',
        path: '',
        component: () => import('@/views/pages/Login'),
        },
      ],
    },
    {
      path: '/logout',
      component: () => import('@/views/pages/Index'),
      children: [
        {
        name: 'Logout',
        path: '',
        component: () => import('@/views/pages/Logout'),
        },
      ],
    },
    {
      path: '/users',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Usuários',
          path: '',
          component: () => import('@/views/users/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Editar Usuário',
          path: 'edit/:id',
          component: () => import('@/views/users/Edit.vue'),
          meta: {
            requiresAuth: true,
            admin: true
          },
        },
        {
          name: 'Criar Usuário',
          path: 'add',
          component: () => import('@/views/users/Edit.vue'),
          meta: {
            requiresAuth: true,
            admin: true
          },
        },
        {
          name: 'Perfil',
          path: 'profile',
          component: () => import('@/views/users/Profile.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ],
    },
    {
      path: '/stocks',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Estoque',
          path: '',
          component: () => import('@/views/stocks/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Editar Produto',
          path: 'edit/:id',
          component: () => import('@/views/stocks/Edit.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Criar Produto',
          path: 'add',
          component: () => import('@/views/stocks/Edit.vue'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/clients',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Clientes',
          path: '',
          component: () => import('@/views/clients/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Editar Cliente',
          path: 'edit/:id',
          component: () => import('@/views/clients/Edit.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Criar Cliente',
          path: 'add',
          component: () => import('@/views/clients/Edit.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Criar Cliente Requisição',
          path: 'add_from_request/:requestId',
          component: () => import('@/views/clients/Edit.vue'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/orders',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Ordens',
          path: '',
          component: () => import('@/views/orders/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Editar Ordem',
          path: 'edit/:id',
          component: () => import('@/views/orders/Edit.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Criar Ordem',
          path: 'add',
          component: () => import('@/views/orders/Edit.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Criar Ordem Cliente',
          path: 'add_from_client/:clientId',
          component: () => import('@/views/orders/Edit.vue'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/requests',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Requisições de Serviço',
          path: '',
          component: () => import('@/views/requests/Index'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Editar Requisição',
          path: 'edit/:id',
          component: () => import('@/views/requests/Edit.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          name: 'Criar Requisição',
          path: 'add',
          component: () => import('@/views/requests/Request.vue'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/generate-order',
      component: () => import('@/views/pages/EmptyIndex.vue'),
      children: [
        {
          name: 'Generate Order',
          path: ':id',
          component: () => import('@/views/orders/Generate.vue'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/solicitar',
      component: () => import('@/views/pages/IndexSolicitar'),
      children: [
        // Dashboard
        {
          name: 'Solicitar',
          path: '',
          component: () => import('@/views/requests/Request.vue'),
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: '/acompanhar',
      component: () => import('@/views/pages/IndexSolicitar'),
      children: [
        // Dashboard
        {
          name: 'Acompanhar',
          path: '',
          component: () => import('@/views/requests/Track.vue'),
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log(localStorage.getItem('jwt'))
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      })
    } else {
      const user = JSON.parse(localStorage.getItem('user'))
      if (to.matched.some(record => record.meta.admin)) {
        if (user.admin === 1) {
          next()
        } else {
          next()
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
